const MultiImage = (props) => {

    return (
        <div className='text-white border'>
            <div className="row d-flex ">
                <div className="fw-semibold fst-italic text-white fs-2 py-2 text-center ">{props.data["NAME"]} <hr className="mx-5 " /></div>
            </div>

            <div className="row justify-content-start p-3">
                {new Array(+Math.ceil(props.data["PRODUCTS"].length / 2)).fill(1).map((item, key) => {
                    return (
                        <div key={key} className="row align-items-end">
                            {new Array(+2).fill(0).map((item1, key1) => {
                                const datakey = ((key * 2) + key1);
                                if (datakey > props.data["PRODUCTS"].length - 1) return (<div></div>)
                                const dataitem = props.data["PRODUCTS"][datakey];
                                return (
                                    <div key={key+","+key1} className="col-lg-6">
                                        <div className='d-flex align-items-center'>
                                            <div className="col-5">
                                                <img src={dataitem["URL"]} alt={dataitem["ITEM NAME"]} className={"img-fluid py-2 px-3"} />
                                            </div>
                                            <table className="w-100 fst-italic ">
                                                <tbody>
                                                    <tr className="table-color">
                                                        <th className="p-2">NAME</th>
                                                        <td className="p-2">{dataitem["ITEM NAME"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="p-2">CODE</th>
                                                        <td className="p-2">{dataitem["ITEM CODE"]}</td>
                                                    </tr>
                                                    <tr className="table-color">
                                                        <th className="p-2">RATE</th>
                                                        <td className="p-2">{dataitem["RATE"]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th className="p-2">PKG</th>
                                                        <td className="p-2">{dataitem["PKG"]}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })}
                        </div>
                    )

                })}
            </div>
        </div>
    );
}

export default MultiImage;
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import MultiImage from '../components/MultiImage';
import SingleImage from '../components/SingleImage';

const Products = (props) => {
	const { id } = useParams();

	const data=props.data[id]

		

	const renderData = (subdata,size) => {	
		if(subdata["SUB"]!==undefined) {
			return (
				<div className="px-md-3 py-2 my-2">
					<div className={"fw-semibold fst-italic text-white mx-3 fs-"+size}>{subdata["NAME"]}</div>
					{subdata["SUB"].map((item,key) => {
						return (
							<div key={key}>
								{renderData(item,(size+1))}
							</div>
						)

					})}
				</div>
			)
		}
		else {
			return (
				<div className="px-md-3 py-2 my-2">
					
					{subdata["1 IMAGE PER ITEM"] === true && <MultiImage data={subdata} />}

					{subdata["1 IMAGE PER ITEM"] === false && <SingleImage data={subdata} />}

				</div>
			)
		}
	}
	
	return (
		<div className='container py-3 d-block'>
			<div className='mx-md-5 px-md-2'>
				<nav style={{"--bs-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"}} aria-label="breadcrumb" className='pt-4'>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/" className='text-light'>Home</Link></li>
						<li className="breadcrumb-item active text-white-50" aria-current="page">{data["NAME"]}</li>
					</ol>
				</nav>
				<p className="fw-light text-white description-text d-block d-sm-none p-2">{data["DESCRIPTION"]}</p>

				<div>
					{renderData(data,1)}
				</div>
			</div>
		</div>
	);
}

export default Products;
import { useState, useEffect } from 'react';

import { read, utils } from "xlsx";

const getFilteredArray = (parent,currLevel,category) => {
    return category.filter(columns=>(columns["LEVEL"]===currLevel && columns["PATH"].startsWith(parent))).sort((a, b) => a['SORTING ALIAS'] - b['SORTING ALIAS']);
}
const recursivePath = (arr,products,category) => {
    let response=[]
    arr.forEach(row => {
        row["NAME"]=row["PATH"].split("/").pop();

        let subArr = getFilteredArray(row["PATH"],row["LEVEL"]+1,category)
        if(subArr.length===0) 
            row["PRODUCTS"] = products.filter(columns=>columns["PATH"]===row["PATH"]).map(({PATH, ...remaining})=>remaining)
        else 
            row["SUB"]=recursivePath(subArr,products,category)
        delete row.PATH       
        delete row.LEVEL       
        response.push(row)
    });
    return response
}

function GetData(id = null) {

	const [data, setData] = useState(null);

	const [isPendingData, setIsPendingData] = useState(true);

	const [errorData, setErrorData] = useState(null);

	useEffect(() => {
		fetch("https://docs.google.com/spreadsheets/d/1oZp75AmGDKRwuOBEh2ww3S6HXC19ib2F/export?format=xlsx")
			.then(r => r.arrayBuffer())
			.then((f) => {
				const wb = read(f); 
    			let ws = wb.Sheets[wb.SheetNames[0]]; 
    			const products = utils.sheet_to_json(ws); 

			    ws = wb.Sheets[wb.SheetNames[1]]; 
    			const category = utils.sheet_to_json(ws).map(columns=>({...columns, "LEVEL":columns["PATH"].split("/").length - 1})); 
    			let arr = getFilteredArray("/",1,category)
				setData(recursivePath(arr,products,category));
				// console.log(data)
				setIsPendingData(false);
				setErrorData(null);
			})
			.catch((err) => {
				setErrorData(err.message)
				setIsPendingData(false);
				console.log(err);
			});
	}, []);

	return { data, isPendingData, errorData };
}

export default GetData;
import Products from '../components/Products';
import { Link } from "react-router-dom";
import { useRef } from 'react';

const Home = (props) => {

    const header = useRef();     

    return ( 
        <div className="container-md">
            <div id="home" className="pt-5 px-5 pb-4" ref={header}>
                <div className="row text-white text-center align-items-center">
                    <div className="col-xl-6 col-12">
                        <h1 className="lh-base fw-bold fs-1">Best electrical accessories for your home</h1>
                        <div className='text-center pt-3'>
                            <button type="button" className="btn btn-light mx-3 px-md-4 my-2" onClick={() => {
                                document.scrollingElement.scrollBy(0,header.current.scrollHeight);
                            }}>Product Categories</button>
                            <Link type="button" className="btn btn-outline-light px-md-4 my-2" to="/contactus">Contact Us</Link>
                        </div>
                    </div>
                    <h1 className="col-xl-6 col-12 d-flex justify-content-center"><span className="fw-bolder fs-1 fst-italic">Ahuja</span><span className="fs-4 fw-bolder">®</span></h1>
                </div>
            </div>      
            <hr className="border px-md-5"/>
            <div className='pb-5 px-md-5' >
                <Products data={props.data}/>
            </div>
        </div>
        
     );
}
 
export default Home;
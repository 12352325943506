import Me from "./Me"

const Footer = () => {
    return ( 
        <div className="border-top border-bottom border-dark bg-light py-4 text-color align-items-center">
            <div className="container">
                <div className="row align-items-center justify-content-center px-md-5 mx-md-3 text-center">
                    <p className="mb-0 col-md-6 text-md-start">Copyright 2023 &copy;</p>
                    <Me />

                </div>
            </div> 
        </div>
     );
}
 
export default Footer;
import { Link } from "react-router-dom";

const About = () => {
    return ( 
        <div className="container text-white">
            <div>
                <nav style={{"--bs-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"}} aria-label="breadcrumb" className='pt-4 mx-2'>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" className="text-light">Home</Link></li>
                        <li className="breadcrumb-item active text-white-50" aria-current="page">About us</li>
                    </ol>
                </nav>
            </div>
            <div className="mx-md-5">
                <h2 className="py-4 mx-md-2 fw-bold fst-italic">About Us</h2>

                <div className="bg-color border rounded m-2 p-3">
                    <div className="row g-0 align-items-center justify-content-around">
                        <div className="col-lg-6">
                            <div className="card-body">
                                <p className="card-text">
                                    Ahuja Products is a electrical accessories provider located in India. We were founded in 1995 with the mission of making technology accessible and convenient for everyone, by offering a wide range of products at affordable prices.

                                    </p>
                            </div>
                        </div>
                        <div className="col-lg-6 navbar-brand fs-1 text-white text-center fw-bold fst-italic">Ahuja Products</div>
                    </div>
                </div>

                <div className="bg-color border rounded m-2 p-3">
                    <div className="row g-0 align-items-center justify-content-around">
                        <div className="col-md-6 ml-auto p-5">
                        <h4 className="text-primary">100+</h4>
                        <p>Clients</p>
                        <h4 className="text-primary">150+</h4>
                        <p>Products</p>
                    </div>
                        <div className="col-lg-6">
                            <div className="card-body">
                                <p className="card-text">
                                    At Ahuja Products, we are committed to providing high-quality electrical accessories to our customers. Over the years, we have developed a reputation for excellence and innovation, and are proud to be the trusted choice for thousands of customers around the world. We continue to strive for excellence in everything we do and are always looking for ways to improve and better serve our customers.
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className="bg-color border rounded m-2 p-3">
                    <div className="row g-0 align-items-center justify-content-around">
                        <div className="col-lg-6">
                            <div className="card-body">
                                <p className="card-text">
                                    Our product range includes accessories for the home and office. We are dedicated to providing our customers with the best possible experience, from the moment they start browsing our products, to the moment they receive their purchase. Our expert customer service team is always on hand to answer any questions and provide support, so you can shop with confidence.
                                    <br/>
                                    <br/>
                                    Thank you for considering Ahuja Products as your preferred supplier of electrical accessories. We look forward to serving you soon!
                                    </p>
                            </div>
                        </div>
                        <div className="col-lg-6 fs-1 fw-bold fst-italic text-center">THANK YOU!!!</div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default About;
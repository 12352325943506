import { Link } from "react-router-dom";


const Navigation = () => {
    return ( 
        <nav className="navbar navbar-expand-lg bg-white sticky-top">
            <div className="container-md">
                <div className="row px-md-5 w-100 d-flex align-items-center justify-content-between">
                        <Link className="navbar-brand fs-1 text-color col-8 col-md-5 px-3" to="/"><i><b>Ahuja Products</b></i></Link>
                        <div className="col-4 col-md-5 justify-content-end d-flex d-lg-none">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>

                        <div className="collapse navbar-collapse col-12 col-lg-5" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-lg-auto me-auto me-lg-0 mb-2 mb-lg-0">
                                <li className="nav-item mx-2">
                                    <Link className="nav-link text-color" to="/"><i>Home</i></Link>
                                </li>
                                <li className="nav-item mx-2">
                                    <Link className="nav-link text-color" to="/about"><i>About</i></Link>
                                </li>
                                <li className="nav-item mx-2">
                                    <Link className="nav-link text-color" to="/contactus"><i>Contact us</i></Link>
                                </li>
                            </ul>
                        </div>  
                </div>
            </div>
        </nav>
     );
}
 
export default Navigation;
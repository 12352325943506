import './App.css';
import Home from './pages/Home';
import Products from './pages/Products';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import About from './pages/About';
import Contact from './pages/Contact';
import GetData from "./components/get_data";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RotatingLines } from "react-loader-spinner";

function ErrorTesting(isPendingData, errorData, pageTag) {
    return (  
        <div>
          {(errorData && <div>{errorData}</div>)}
					{!errorData && isPendingData && <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />}
					{!errorData && !isPendingData && <div  className="d-flex justify-content-around">{pageTag}</div>}
        </div>
    );
}

function App() {
  const { data, isPendingData, errorData } = GetData();
  // console.log(data)
  return (
    <div className="App bg-color text-white">
      <Router>

        <Navigation />
        <Routes>
          <Route path="/" exact element={ErrorTesting(isPendingData, errorData, <Home data={data} />)} />
          <Route path="/products/:id" element={ErrorTesting(isPendingData, errorData, <Products data={data} />)} />
          <Route path='/about' element={<About />} />
          <Route path='/contactus' element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

import { Link } from "react-router-dom";

const Products = (props) => {

    return ( 
        <div id="products" className="container-md">
            <h1 className='fs-1 fw-bold text-white fst-italic pb-4 text-center text-md-start'>Product Categories</h1>
            <div className="row justify-content-around">
            {
                props.data.map((item, key) =>
                <Link to={"/products/" + key} key={key} className="text-decoration-none text-white text-center my-2 d-flex p-3 justify-content-center col-12 col-lg-6 ">
                {/* {console.log(item)} */}
                    <div className="row g-0 zoom d-flex align-items-center px-3 w-100 border border-2 border-light rounded-5">
                        <div className="col-sm-6" ><img src={item["URL"]} alt={item["NAME"]} className="img-fluid p-3"/></div>
                        <div className="col-sm-6">
                            <div className="card-body">
                                <h3 className="card-title">{item["NAME"]}</h3>
                                <br />
                                <p className="card-text fw-light description-text d-none d-sm-block">{item["DESCRIPTION"]}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                )
            }
            </div>
        </div>
     );
}
 
export default Products;
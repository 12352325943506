const SingleImage = (props) => {

    return (
        <div className="py-2 text-white border d-lg-flex">
            <div className="col-lg-6 col-12 align-self-center">
                <div className="fw-semibold fst-italic fs-2 text-center">{props.data["NAME"]}</div>
                <img src={props.data["URL"]} alt={props.data["NAME"]} className="p-4 img-fluid col-12" />
            </div>

            <div className="p-3 pt-2 w-100">
                <table className="w-100 fst-italic">
                    <thead className="fw-semibold text-center fs-6">
                        <tr className="table-color">
                            <th className="text-start ps-2 py-2">NAME</th>
                            <th className="px-2">CODE</th>
                            <th className="px-2">RATE</th>
                            <th className="px-2">PKG</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {props.data["PRODUCTS"].map((dataitem, key) => {
                            return (
                                <tr key={key} className={"text-center "+ (key%2===0?'':'table-color')}>
                                    <td className="text-start ps-2 py-2">{dataitem["ITEM NAME"]}</td>
                                    <td>{dataitem["ITEM CODE"]}</td>
                                    <td>{dataitem["RATE"]}</td>
                                    <td>{dataitem["PKG"]}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SingleImage;
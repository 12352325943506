import { Link } from "react-router-dom";

const Contact = () => {
    return ( 
        <div className="container text-white">
            <div>
                <nav style={{"--bs-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E&#34;);"}} aria-label="breadcrumb" className='pt-4 mx-2'>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" className="text-light">Home</Link></li>
                        <li className="breadcrumb-item active text-white-50" aria-current="page">Contact us</li>
                    </ol>
                </nav>
                <div className="mx-md-5">
                    <h3 className="section-title">Contact us</h3>

                    <div className="row align-items-center p-1">
                        <div className="mx-md-2">
                            <li className="py-3 my-2 row border">
                                <div className="col-5 col-md-4">Address &nbsp;<i className="fa-solid fa-location-dot"></i></div>
                                <div className="col-7 col-md-8"> Ahuja Products, Gala No. 1, Aaima Industrial Estate, Tungarfata, Sativali, Vasai East, Palghar 401208 </div>
                            </li>
                            <li className="py-3 my-2 row border">
                                <div className="col-5 col-md-4">Email ID &nbsp;<i className="fa-solid fa-envelope"></i></div>
                                <div className="col-7 col-md-8"> <a href="mailto:ahujaproducts1@gmail.com" className="text-white">ahujaproducts1@gmail.com</a></div>
                            </li>
                            <span className="border row py-3 my-2">
                                <div className="col-5 col-md-4">Manager &nbsp;<i className="fa-solid fa-user"></i></div>
                                <div className="col-7 col-md-8">Naresh Narang</div>
                                <div className="col-5 col-md-4 pt-3">Contact No.&nbsp;<i className="fa-solid fa-phone"></i></div>
                                <div className="col-7 col-md-8 pt-3"><a href="tel:+919821162721" className="text-white">+919821162721</a></div>
                            </span>
                            <span className="py-3 my-2 border row">
                                <div className="col-5 col-md-4">Owner &nbsp;<i className="fa-solid fa-user"></i></div>
                                <div className="col-7 col-md-8">Narendra Ahuja</div>
                                <div className="col-5 col-md-4 pt-3">Contact No.&nbsp;<i className="fa-solid fa-phone"></i></div>
                                <div className="col-7 col-md-8 pt-3"><a href="tel:+919324659229" className="text-white">+919324659229</a></div>
                            </span>
                            <h6 className="py-3 text-center text-md-start fs-6">We'll get back to you within 2 working days.</h6>

                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Contact;
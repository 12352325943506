const Me = () => {
    return ( 
        <span className="col-md-6 ">
            <p className="mb-0 text-md-end"  style={{"color": "#8080ff"}}>Designed by <span type="button" data-bs-toggle="modal" data-bs-target="#designer" className="text-decoration-underline">DR.WebView</span>&nbsp;<i className="fa-solid fa-circle-nodes fa-2xl"></i></p>

            <div className="modal fade" id="designer" style={{"color": "#8080ff"}} tabIndex="-1" aria-labelledby="designer" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="designer">Contact Info:</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-start fw-light ">
                            <li className="py-3 my-2 row border">
                                <div className="col-5">Email ID&nbsp;<i className="fa-solid fa-envelope"></i></div>
                                <div className="col-7"><a href="mailto:dr.webview@gmail.com" style={{"color": "#8080ff"}}>dr.webview@gmail.com</a></div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </span>
     );
}
 
export default Me;